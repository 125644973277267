<template>
  <div
    class="container"
    v-title
    id="maintenanceMonth"
    :data-title="$t('i18n.maintenanceMonth')"
  >
    <div id="outer-title">{{ $t("i18n.maintenanceMonth") }}</div>
    <jl-form
      :columns="searchColumns"
      inline="true"
      :option="btnOption"
    ></jl-form>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane :label="$t('i18n.business')" name="1">
        <div class="table" v-for="item in tableArr" :key="item">
          <p>{{ $t("i18n." + item.title) }}</p>
          <el-table :data="item.tableData" border :style="item.style">
            <el-table-column width="200" align="center" label=" ">
              <template #default="scope">
                {{ $t("i18n." + scope.row.label) }}
              </template>
            </el-table-column>
            <el-table-column
              v-for="child in item.list"
              :key="child"
              align="center"
              prop="date"
              :label="
                child.indexOf(']') > -1 || child === ''
                  ? child
                  : $t('i18n.' + child)
              "
            ></el-table-column>
          </el-table>
        </div>
      </el-tab-pane>
      <el-tab-pane :label="$t('i18n.finance')" name="2">
        <div class="table" v-for="item in tableArr1" :key="item">
          <p v-if="item.title">{{ $t("i18n." + item.title) }}</p>
          <el-table :data="item.tableData" border :style="item.style">
            <el-table-column width="200" align="center" label=" ">
              <template #default="scope">
                {{ $t("i18n." + scope.row.label) }}
              </template>
            </el-table-column>
            <el-table-column
              v-for="child in item.list"
              :key="child"
              align="center"
              prop="date"
              :label="child === '' ? child : $t('i18n.' + child)"
            ></el-table-column>
          </el-table>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
import jlForm from "../../components/form";
export default {
  name: "ReportMaintain",
  components: {
    jlForm,
  },
  setup(props, { emit }) {
    emit("public_header", true);
    const { proxy } = getCurrentInstance();
    const state = reactive({
      searchColumns: [
        {
          prop: "date",
          label: "date",
          type: "date",
        },
      ],
      btnOption: [
        {
          label: "query",
          class: "primary",
          show: true,
        },
        {
          label: "export",
          class: "export",
          show: true,
        },
      ],
      activeName: "1", // 选项卡选中
      tableArr: [
        {
          title: "currentMaintenanceUnits",
          tableData: [
            { label: "freeMaintenanceUnits", date: "" },
            { label: "paidMaintenanceUnits", date: "" },
            { label: "mainUnits", date: "" },
          ],
          list: ["totalUnits", "selfMaintenanceUnits", "contractAwardUnits"],
          style: { width: "50%" },
        },
        {
          title: "expiringContractUnits",
          tableData: [
            { label: "freeMaintenanceUnits", date: "" },
            { label: "paidMaintenanceUnits", date: "" },
          ],
          list: ["overdue", "(0, 30]", "(30, 60]"],
          style: { width: "50%" },
        },
        {
          title: "maintenanceUnitsChange",
          tableData: [
            { label: "maintenanceHeadCount", date: "" },
            { label: "repairHeadCount", date: "" },
            { label: "newFreeMaintenance", date: "" },
            { label: "dueFreeMaintenance", date: "" },
            { label: "freeToPaidMaintenance", date: "" },
            { label: "lostDueFreeMaintenance", date: "" },
            { label: "duePaidMaintenance", date: "" },
            { label: "renewPaidMaintenance", date: "" },
            { label: "lostPaidMaintenance", date: "" },
            { label: "newPaidMaintenance", date: "" },
          ],
          list: proxy.$config.month,
          style: { width: "100%" },
        },
        {
          title: "maintenanceRepair",
          tableData: [
            { label: "selfMaintenanceUnits", date: "" },
            { label: "currentMonthMaintenanceTimes", date: "" },
            { label: "averageMaintenanceTimesByUnit", date: "" },
            { label: "averageMaintenanceTimeConsumingByUnit", date: "" },
            { label: "totalHoursForMaintenance", date: "" },
            { label: "averageMaintenanceTimesByStaff", date: "" },
            { label: "averageMaintenanceUnitsByStaff", date: "" },
            { label: "repairTimes", date: "" },
            { label: "repairTimesByUnit", date: "" },
            { label: "averageResponse", date: "" },
            { label: "averageRepair", date: "" },
            { label: "repairTotalHours", date: "" },
            { label: "totalHoursOfUrgentRepair", date: "" },
            { label: "urgentRepairManHour", date: "" },
          ],
          list: proxy.$config.month,
          style: { width: "100%" },
        },
        {
          title: "trappedFault",
          tableData: [
            { label: "trappedTimes", date: "" },
            { label: "faultRate", date: "" },
            { label: "problemSolvingResponseTime", date: "" },
            { label: "rescueTimeConsuming", date: "" },
          ],
          list: ["", "", "", "", "", "", "", "", "", "", "", ""],
          style: { width: "100%" },
        },
        {
          title: "ineffectiveHoursAnalysis",
          tableData: [
            { label: "distanceTimeConsumingForMaintenance", date: "" },
            { label: "distanceTimeConsumingForUrgentRepair", date: "" },
            { label: "distanceLengthForMaintenance", date: "" },
            { label: "distanceLengthForUrgentRepair", date: "" },
          ],
          list: ["", "", "", "", "", "", "", "", "", "", "", ""],
          style: { width: "100%" },
        },
      ],
      tableArr1: [
        {
          title: "validateContract",
          tableData: [
            { label: "maintenancePerformed", date: "" },
            { label: "partsPerformed", date: "" },
            { label: "overhaulPerformed", date: "" },
            { label: "remouldPerformed", date: "" },
            { label: "totalAmount", date: "" },
          ],
          list: proxy.$config.month,
          style: { width: "100%" },
        },
        {
          title: "billing",
          tableData: [
            { label: "maintenanceBilling", date: "" },
            { label: "partsBilling", date: "" },
            { label: "overhaulBilling", date: "" },
            { label: "remouldBilling", date: "" },
            { label: "totalAmount", date: "" },
          ],
          list: proxy.$config.month,
          style: { width: "100%" },
        },
        {
          title: "",
          tableData: [
            { label: "currentYearBillingRevenue", date: "" },
            { label: "accountsReceivable", date: "" },
          ],
          list: [
            "maintenance",
            "replacements",
            "overhaul",
            "remould",
            "totalAmount",
          ],
          style: { width: "50%" },
        },
        {
          title: "expectedReceivableAgeAnalysis",
          tableData: [
            { label: "moreThan3Months", date: "" },
            { label: "to36Months", date: "" },
            { label: "to612Months", date: "" },
            { label: "to12Years", date: "" },
            { label: "moreThan2Years", date: "" },
            { label: "totalAmount", date: "" },
          ],
          list: [
            "maintenance",
            "replacements",
            "overhaul",
            "remould",
            "totalAmount",
          ],
          style: { width: "50%" },
        },
      ],
    });

    const onSubmit = () => {};

    const init = () => {};

    init();

    return {
      ...toRefs(state),
      onSubmit,
    };
  },
};
</script>

<style lang="scss">
#maintenanceMonth {
  .table {
    margin: 20px 0;
    p {
      margin-bottom: 10px;
    }
    .el-table {
      margin-top: 7px;
      th,
      td:first-child {
        background: $blue2_color;
        padding: 5px 0;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  #maintenanceMonth {
    .table {
      .el-table {
        width: 100% !important;
      }
    }
  }
}
</style>
